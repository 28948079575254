<script setup lang="ts">
interface propType {
  data: {
    id: number
    image: string
    color_mode: 'light' | 'dark'
    translations: DirectusTranslation<{
      quote: string
      name: string
    }>
  }
}

const props = defineProps<propType>()
</script>

<template>
  <BlockBase :is-light="props.data.color_mode === 'light'" class="block-light:bg-neutral-white block-light:text-neutral-black">
    <Container class="flex items-center justify-center py-12 lg:py-15rem">
      <div class="flex flex-col justify-center gap-4 lg:flex-row lg:items-center lg:gap-3rem">
        <div class="size-130px lg:size-143px">
          <NuxtImg
            loading="lazy"
            provider="directus"
            :src="props.data.image"
            :height="143" :width="143"
            class="size-full object-cover"
          />
        </div>

        <div>
          <h3 class="max-w-45rem text-balance text-2rem font-title lg:text-2.5rem">
            “{{ dt(props.data.translations)?.quote }}”
          </h3>
          <p class="mt-1rem text-1rem text-neutral-gray tracking-3px uppercase lg:text-1.3rem">
            {{ dt(props.data.translations)?.name }}
          </p>
        </div>
      </div>
    </Container>
  </BlockBase>
</template>

<style>
</style>
